import React from "react";
import ValuesPic from "assets/vision.webp";
import Content from "components/Content";
import { Stack, Typography } from "@mui/material";

function Vision() {
  return (
    <Content
      imageProps={{
        image: ValuesPic,
        alt: "Vision",
        sizes:
          "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
      }}
      direction="row"
    >
      <Stack justifyContent="center" spacing={2}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Vision
        </Typography>
        <Typography variant="body1" paragraph>
          Our vision is to focus on a curriculum that balances academics,
          co-curricular activities and personality development with a view to
          enable each child to become a global citizen. We strive to inculcate
          and nurture leadership qualities along with self-discipline, ethical
          principles, respect and concern for others. This will empower students
          to acquire, demonstrate, articulate, and value knowledge and skills
          that will support them, as life-long learners, to participate in and
          contribute to the global world and practice the core values of the
          school.
        </Typography>
      </Stack>
    </Content>
  );
}

export default Vision;
