import React from "react";
import Curriculum from "components/Curriculum";
import MethodologyImage from "assets/preprimary.webp";
// @ts-ignore
import BGImage from "assets/primary-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";

function PrePrimarySchool() {
  return (
    <Curriculum
      bgImage={BGImage}
      heading="Pre-Primary School"
      title="Pre-primary School (Nursery, LKG & UKG)"
      description="The Pre-Primary School Program at Pride International School comprises of Nursery, Lower and Upper Kindergarten (LKG and UKG). Early childhood education serves as the foundation for all future learning. Nestled in greenery and equipped with an excellent play area and an environment conducive to the development of their creativity, they blossom into individuals. Budding minds are enthused through a combination of formal and informal learning, indoor and outdoor activities such as singing and recitation, reading and writing, games and physical education.Our curriculum focuses on the developmental milestones in the areas of cognitive, creative, social and physical development. This learning design leads a child to inquire and seek answers or to find answers on her own."
      methodologyImage={MethodologyImage}
      methodologyDetails={[
        {
          title: "Evaluation",
          description:
            "Students are not tested formally but their progress is measured gradually through small fun-filled activities over a three term system.",
        },
      ]}
    />
  );
}

export default PrePrimarySchool;
