import React from "react";
import { ContactInvite, AdmissionInvite } from "components/Invite";
import HeadingDisplay from "components/HeadingDisplay";
// @ts-ignore
import BGImage from "assets/vmv-bg.webp?sizes[]=300,sizes[]=790,sizes[]=1070,sizes[]=1290,sizes[]=1480,sizes[]=1650,sizes[]=1800,sizes[]=1930,sizes[]=2048";
import MainStack from "components/MainStack";
import { Box } from "@mui/material";
import Values from "./Values";
import Mission from "./Mission";
import Vision from "./Vision";

function VMV() {
  return (
    <Box>
      <HeadingDisplay
        bgImage={BGImage}
        displayText="Values, Mission & Vision"
      />
      <MainStack>
        <Values />
        <ContactInvite />
        <Mission />
        <AdmissionInvite />
        <Vision />
      </MainStack>
    </Box>
  );
}

export default VMV;
