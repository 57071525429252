import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import SecurityIcon from "@mui/icons-material/Security";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Icon,
  Stack,
  Typography,
} from "@mui/material";

function FAIcon(props) {
  const { name, ...rest } = props;
  return (
    <Box py={1}>
      <Icon
        baseClassName="fa-solid"
        className={name}
        sx={{ fontSize: 75, overflow: "visible" }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </Box>
  );
}
FAIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

function MuiIcon(props) {
  const { IconComponent, ...rest } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconComponent sx={{ fontSize: 90 }} {...rest} />;
}
MuiIcon.propTypes = {
  IconComponent: PropTypes.elementType.isRequired,
};

const features = [
  {
    title: "Qualified and Experienced Faculty",
    description:
      "Highly qualified and experienced faculty members to ensure quality education.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <FAIcon name="fa-person-chalkboard" {...props} />,
    href: "/management",
  },
  {
    title: "Infrastructure",
    description:
      "State-of-the-art infrastructure with modern amenities to ensure a conducive learning environment.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MuiIcon IconComponent={ApartmentIcon} {...props} />,
    href: "/classroom",
  },
  {
    title: "Extracurricular Activities",
    description:
      "A plethora of extracurricular activities to ensure holistic development of students.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MuiIcon IconComponent={SportsHandballIcon} {...props} />,
    href: "/sports-facilities",
  },
  {
    title: "Parent-Teacher Collaboration",
    description:
      "Parent-teacher collaboration to ensure overall development of students.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MuiIcon IconComponent={Diversity3Icon} {...props} />,
    href: "/gallery",
  },
  {
    title: "Student-Centric Approach",
    description:
      "Student-centric approach to ensure that each student gets the attention they deserve.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <FAIcon name="fa-users-viewfinder" {...props} />,
    href: "/learning-methodology",
  },
  {
    title: "Digital Learning",
    description:
      "Digital learning to ensure that students are ready for the future.",
    icon: (props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <MuiIcon IconComponent={CastForEducationIcon} {...props} />
    ),
    href: "/classroom",
  },
  {
    title: "Safe and Secure Campus",
    description:
      "Safe and secure campus to ensure that students are safe and secure.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MuiIcon IconComponent={SecurityIcon} {...props} />,
    href: "/safety-security",
  },
  {
    title: "Health and Wellness",
    description:
      "Health and wellness programs to ensure that students are healthy and happy.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MuiIcon IconComponent={MonitorHeartIcon} {...props} />,
    href: "/gallery",
  },
  {
    title: "Transportation",
    description:
      "Transportation facilities to ensure that students can reach the campus easily.",
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MuiIcon IconComponent={DirectionsBusIcon} {...props} />,
    href: "/safety-security",
  },
];

function Features() {
  const navigate = useNavigate();

  return (
    <Box>
      <Container>
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 6 }}
        >
          Why Choose Us?
        </Typography>
        <Grid container spacing={7} justifyContent="center">
          {features.map((feature) => (
            <Grid item xs={12} sm={6} lg={4} key={feature.title}>
              <Card
                sx={{
                  height: 350,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  py: 2,
                  "&:hover": {
                    transform: "scale3d(1.05, 1.05, 1)",
                    "& .MuiCardActions-root .MuiButton-root": {
                      color: "primary.main",
                      backgroundSize: "100% 100%",
                    },
                  },
                  transition: "transform 0.2s ease",
                }}
                raised
              >
                <CardContent>
                  <Stack spacing={2} alignItems="center">
                    <feature.icon color="secondary" />
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontWeight: "fontWeightMedium" }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ color: "text.secondary" }}
                    >
                      {feature.description}
                    </Typography>
                  </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => navigate(feature.href)}
                    sx={{
                      border: 2,
                      borderColor: "primary.main",
                      position: "relative",
                      transition:
                        "color 0.2s ease-out, background-size 0.2s ease-out",
                      backgroundImage: (theme) =>
                        `linear-gradient(${theme.palette.common.white}, ${theme.palette.common.white})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "50% 50%",
                      backgroundSize: "0% 100%",
                    }}
                  >
                    Learn more
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Features;
