import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useHandleNavbarMenu = ({ isMenu, href, handleParentClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (event) => {
    if (isMenu) {
      const newAnchorEl = anchorEl ? null : event.currentTarget;
      setAnchorEl(newAnchorEl);
    } else {
      navigate(href);
      handleMenuClose();
      handleParentClose();
    }
  };

  return { anchorEl, handleButtonClick, handleMenuClose };
};

export default useHandleNavbarMenu;
