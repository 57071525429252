import React from "react";
import BGImage from "assets/classroom-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import { Box, Stack, Typography } from "@mui/material";
import HeadingDisplay from "components/HeadingDisplay";
import Content from "components/Content";
import Classroom1Pic from "assets/classroom1.webp";
import Classroom2Pic from "assets/classroom2.webp";
import MainStack from "components/MainStack";

const classrooms = [
  {
    title: "Pre-Primary Classrooms (Nursery, LKG, UKG)",
    description:
      "The pre-primary classrooms are equipped with child-friendly furniture like low detachable tables and low chairs, arranged in groups for optimum interaction and intermingling amongst the children. Smart boards equipped with the latest digital content help the children enjoy learning and facilitate interaction. Every classroom has optimum student capacity to ensure an appropriate and conducive surrounding.",
    image: Classroom1Pic,
  },
  {
    title: "Primary, Middle & Secondary Classrooms (Grade I - X)",
    description:
      "The primary, middle & senior school classroom are equipped with age appropriate and ergonomic furniture which provides the students ample space to keep their belongings and comfortable seating throughout the school hours. Every classrooms has optimum student capacity to ensure best teaching- learning experience. Facilities are provided at this level, keeping in mind their physical comfort, security and safe-keeping. Projector  and smart boards are integral features of the classroom, providing maximum comfort and integration of knowledge respectively, to accelerate the teaching-learning process.",
    image: Classroom2Pic,
  },
];
function Classroom() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Classroom" />
      <MainStack>
        {classrooms.map((classroom, index) => (
          <Content
            imageProps={{
              image: classroom.image,
              alt: classroom.title,
              sizes:
                "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
            }}
            key={classroom.name}
            direction={index % 2 ? "row-reverse" : "row"}
          >
            <Stack justifyContent="center" spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {classroom.title}
              </Typography>
              <Typography variant="body1">{classroom.description}</Typography>
            </Stack>
          </Content>
        ))}
      </MainStack>
    </Box>
  );
}

export default Classroom;
