import React from "react";
// @ts-ignore
import BGImage from "assets/safety-security.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "components/HeadingDisplay";
import { Box, Stack, Typography } from "@mui/material";
import Content from "components/Content";
import EnvironmentPic from "assets/environment.webp";
import CCTVPic from "assets/cctv.webp";
import TransportationPic from "assets/transportation.webp";
import { ContactInvite } from "components/Invite";
import MainStack from "components/MainStack";

const facilities = [
  {
    title: "Clean and Safe Environment",
    description:
      "We have a calm and serene area so that students start their day with lot of positivity. The school provides a clean and safe environment for the students. The school has a dedicated team of staff who ensure that the environment is clean and safe for the students. Cleaning and sanitization work of school is carried out daily, as we understand the importance of a clean and germ free environment for our children.",
    image: EnvironmentPic,
  },
  {
    title: "CCTV Surveillance",
    description:
      "The entire campus is equipped with continuous CCTV surveillance to ensure the safety and well-being of the students. We have equipped  CCTV cameras in the campus with a surveillance room to monitor them. Regular monitoring by a dedicated safety and security officer, makes the entire process completely transparent, so that any untoward event may be averted. The school has a dedicated team of staff who monitor the cameras and ensure the safety of the students.",
    image: CCTVPic,
  },
  {
    title: "Transportation",
    description:
      "The school provides transportation facilities to ensure that students can reach the campus easily. The school has a fleet of buses which are well maintained and driven by experienced drivers. The buses are equipped with GPS and CCTV cameras to ensure the safety of the students. The school has a dedicated team of staff who ensure that the buses are clean and safe for the students.",
    image: TransportationPic,
  },
];

function SafetySecurity() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Safety & Security" />
      <MainStack>
        {facilities.map((facility, index) => (
          <>
            <Content
              imageProps={{
                image: facility.image,
                alt: facility.title,
                sizes:
                  "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
              }}
              key={facility.title}
              direction={index % 2 ? "row-reverse" : "row"}
            >
              <Stack justifyContent="center" spacing={2}>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {facility.title}
                </Typography>
                <Typography variant="body1">{facility.description}</Typography>
              </Stack>
            </Content>
            {index === 1 && <ContactInvite />}
          </>
        ))}
      </MainStack>
    </Box>
  );
}

export default SafetySecurity;
