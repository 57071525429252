import React from "react";
// @ts-ignore
import BGImage from "assets/LM-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "components/HeadingDisplay";
import { Box } from "@mui/material";
import MainStack from "components/MainStack";
import Methodologies from "./Methodologies";
import CurriculumAims from "./CurriculumAims";

function LM() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Learning Methodology" />
      <MainStack>
        <Methodologies />
      </MainStack>
      <CurriculumAims />
    </Box>
  );
}

export default LM;
