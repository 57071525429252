import React from "react";
import { useForm, Controller } from "react-hook-form";
import PersonIcon from "@mui/icons-material/PersonRounded";
import EmailIcon from "@mui/icons-material/EmailRounded";
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded";
import SubjectIcon from "@mui/icons-material/SubjectRounded";
import {
  Grid,
  InputAdornment,
  Stack,
  TextField as MuiTextField,
  Typography,
  Button,
} from "@mui/material";

const getTextField = (fieldProps) =>
  React.forwardRef((props, ref) => {
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    const { icon, ...rest } = fieldProps;
    return (
      <MuiTextField
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
        }}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    );
  });

const formFields = [
  {
    name: "name",
    Field: getTextField({
      required: true,
      label: "Name",
      autoComplete: "given-name",
      icon: <PersonIcon />,
    }),
    rules: { required: "Name is required" },
    gridProps: { xs: 12, sm: 6 },
    defaultValue: "",
  },
  {
    name: "email",
    Field: getTextField({
      required: true,
      label: "Email",
      autoComplete: "email",
      type: "email",
      icon: <EmailIcon />,
    }),
    rules: { required: "Email is required" },
    gridProps: { xs: 12, sm: 6 },
    defaultValue: "",
  },
  {
    name: "contact",
    Field: getTextField({
      required: true,
      label: "Contact",
      autoComplete: "tel",
      type: "tel",
      inputProps: { maxLength: 10, minLength: 10 },
      icon: <PhoneIcon />,
    }),
    rules: {
      required: "Contact number is required",
      pattern: { value: /^[0-9]{10}$/i, message: "Invalid contact number" },
    },
    gridProps: { xs: 12, sm: 6 },
    defaultValue: "",
  },
  {
    name: "subject",
    Field: getTextField({
      required: true,
      label: "Subject",
      icon: <SubjectIcon />,
    }),
    rules: { required: "Subject is required" },
    gridProps: { xs: 12, sm: 6 },
    defaultValue: "",
  },
  {
    name: "message",
    Field: getTextField({
      required: true,
      label: "Message",
      multiline: true,
      rows: 6,
    }),
    gridProps: { xs: 12 },
    defaultValue: "",
  },
];

function QueryForm() {
  const { control, handleSubmit } = useForm({
    defaultValues: formFields.reduce(
      (defaultVals, currValue) => ({
        ...defaultVals,
        [currValue.name]: currValue.defaultValue,
      }),
      {}
    ),
  });
  const onSubmit = () => {
    // TODO: Send data to server
  };
  const getFormField = ({ name, Field, rules = {}, defaultValue }) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <Field
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );

  return (
    <Stack spacing={3}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "fontWeightBold" }}
        gutterBottom
        textAlign="center"
      >
        Contact Us
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} alignItems="center">
          <Grid container spacing={2} justifyContent="center">
            {formFields.map((field) => {
              const { gridProps, ...rest } = field;
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Grid item {...field.gridProps} key={field.name}>
                  {getFormField(rest)}
                </Grid>
              );
            })}
          </Grid>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default QueryForm;
