import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Content from "components/Content";
import MissionPic from "assets/Mission.webp";

const missions = [
  "To develop in our students a strong sense of self-worth and responsibility to prepare them to become life-long learners and balanced individuals.",
  "To create opportunities that enhance and develop in our students an intellectual, social, ethical, emotional and physical potential.",
  "To promote the virtues of compassion and respect for human diversity through a multicultural environment and prepare our students for living, working and contributing in a global society.",
];

function Mission() {
  return (
    <Content
      imageProps={{
        image: MissionPic,
        alt: "Mission",
        sizes:
          "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
      }}
      direction="row-reverse"
    >
      <Stack justifyContent="center" spacing={2}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Mission
        </Typography>
        <Typography variant="body1" paragraph>
          Our mission is to provide a safe, nurturing, and inclusive environment
          that fosters a love of learning, respect for others, and the
          development of the whole child. We are committed to the academic,
          social, and emotional growth of each child and to the development of
          lifelong learners who will be responsible, contributing members of the
          global community.
        </Typography>
        <List disablePadding>
          {missions.map((mission) => (
            <ListItem key={mission} disablePadding>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <ListItemText primary={mission} />
            </ListItem>
          ))}
        </List>
      </Stack>
    </Content>
  );
}

export default Mission;
