import React from "react";
// @ts-ignore
import BGImage from "assets/admissions-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import AdmissionImage from "assets/Admission-Open.jpg";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MainStack from "./MainStack";
import Content from "./Content";
import { ContactInvite2 } from "./Invite";
import HeadingDisplay from "./HeadingDisplay";

const admissionProcess = [
  {
    title: "Fill Up Application",
    text: "Get admission application form from the school counter. Fill in the necessary details accurately.",
  },
  {
    title: "Submit Application",
    text: "Submit the required documents, like academic records, birth certificate, and any other information.",
  },
  {
    title: "Family Interview",
    text: "Our admission team will review the application. Shortlisted candidates will be invited for an interview.",
  },
  {
    title: "Admission Offer",
    text: "Once the interview process is complete, successful candidates will receive an admission offer.",
  },
];

function Admissions() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Admissions" />
      <MainStack>
        <Content
          imageProps={{
            image: AdmissionImage,
            alt: "Admission Open",
            sizes:
              "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
          }}
        >
          <Stack justifyContent="center" spacing={2}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "fontWeightBold" }}
            >
              Welcome to MSY Divine Admissions!
            </Typography>
            <Typography variant="body1" paragraph>
              At MSY Divine School, we believe in nurturing young minds and
              fostering a learning environment that encourages curiosity,
              creativity, and academic excellence. We are delighted that you are
              considering MSY Divine School for your child&apos;s education.
            </Typography>
            <Typography variant="body1" paragraph>
              We look forward to welcoming your family to the MSY divine
              community. If you have any questions or need assistance, please
              feel free to contact our admissions office.
            </Typography>
          </Stack>
        </Content>
        <ContactInvite2 />
        <Box>
          <Container>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ fontWeight: "fontWeightBold", mb: 6 }}
            >
              Admission Process
            </Typography>
            <Grid container spacing={5} justifyContent="center">
              {admissionProcess.map((process, index) => (
                <Grid item xs={12} sm={6} lg={4} xl={3} key={process.title}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      py: 2,
                      "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
                      transition: "transform 0.2s linear",
                    }}
                    raised
                  >
                    <CardContent>
                      <Stack spacing={2} alignItems="center">
                        <Typography
                          sx={{
                            fontWeight: "fontWeightBold",
                            color: "primary.main",
                          }}
                          variant="h2"
                          align="center"
                          component="span"
                        >
                          {index + 1}
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold" }}
                          align="center"
                        >
                          {process.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          align="center"
                          sx={{ color: "text.secondary" }}
                        >
                          {process.text}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </MainStack>
    </Box>
  );
}

export default Admissions;
