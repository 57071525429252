import React, { useLayoutEffect } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { CssBaseline } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Home from "components/Home";
import VMV from "components/VMV";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import LM from "components/LM";
import MC from "components/MC";
import Classroom from "components/Classroom";
import Lab from "components/Lab";
import SF from "components/SF";
import SafetySecurity from "components/SafetySecurity";
import PrePrimarySchool from "components/PrePrimarySchool";
import PrimarySchool from "components/PrimarySchool";
import MiddleSchool from "components/MiddleSchool";
import SeniorSchool from "components/SeniorSchool";
import NoticeBoard from "components/NoticeBoard";
import Gallery from "components/Gallery";
import Brochure from "components/Brochure";
import Admissions from "components/Admissions";
import Contact from "components/Contact";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Wrapper />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "values-mission-vision",
        element: <VMV />,
      },
      {
        path: "learning-methodology",
        element: <LM />,
      },
      {
        path: "management",
        element: <MC />,
      },
      {
        path: "classroom",
        element: <Classroom />,
      },
      {
        path: "laboratories",
        element: <Lab />,
      },
      {
        path: "sports-facilities",
        element: <SF />,
      },
      {
        path: "safety-security",
        element: <SafetySecurity />,
      },
      {
        path: "pre-primary",
        element: <PrePrimarySchool />,
      },
      {
        path: "primary",
        element: <PrimarySchool />,
      },
      {
        path: "middle-school",
        element: <MiddleSchool />,
      },
      {
        path: "senior-school",
        element: <SeniorSchool />,
      },
      {
        path: "notice-board",
        element: <NoticeBoard />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "school-brochure",
        element: <Brochure />,
      },
      {
        path: "admissions",
        element: <Admissions />,
      },
      {
        path: "reach-us",
        element: <Contact />,
      },
    ],
  },
]);

function Wrapper() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

export default App;
