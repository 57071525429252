import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import SchoolPhoto from "assets/MSY.webp";
import Content from "components/Content";

function Welcome() {
  const navigate = useNavigate();
  return (
    <Content
      imageProps={{
        image: SchoolPhoto,
        alt: "School photo",
        shouldApplyBoxShadow: true,
        sizes:
          "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
      }}
    >
      <Stack spacing={2} justifyContent="center">
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Welcome to MSY Divine School
        </Typography>
        <Typography variant="body1" paragraph>
          MSY Divine School is a co-educational institution that is committed to
          providing quality education to the students. We aim to provide
          students with an education that is not only academically excellent but
          also infused with the right values and life skills.
        </Typography>
        <Typography variant="body1" paragraph>
          We believe that every child is unique and has the potential to excel
          in different ways. Our teachers are dedicated to nurturing the unique
          potential of each child and helping them grow into responsible and
          well-rounded individuals.
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{ alignSelf: "flex-start" }}
          color="secondary"
          onClick={() => navigate("/values-mission-vision")}
        >
          Learn More
        </Button>
      </Stack>
    </Content>
  );
}

export default Welcome;
