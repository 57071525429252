import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Content from "components/Content";
import ValuesPic from "assets/values.webp";

const values = [
  "Leading through innovation",
  "Inspiring excellence",
  "Growing by learning",
  "Empowering through knowledge",
];

function Values() {
  return (
    <Content
      imageProps={{
        image: ValuesPic,
        alt: "Values",
        sizes:
          "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
      }}
      direction="row"
    >
      <Stack justifyContent="center" spacing={2}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Values
        </Typography>
        <Typography variant="body1" paragraph>
          At MSY Divine School, we strongly believe in our core values. These
          values define who we are, the beliefs that guide our actions and
          behaviours. Academic performance is important for many reasons. It can
          open the door to opportunity and pave the way for future success, both
          in higher education and professionally. However, we believe a
          values-based, global education is of equal importance.
        </Typography>
        <List disablePadding>
          {values.map((value) => (
            <ListItem key={value} disablePadding>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <ListItemText
                primary={value}
                primaryTypographyProps={{ variant: "h6" }}
              />
            </ListItem>
          ))}
        </List>
        <Typography variant="body1" paragraph>
          With these values at our core, we encourage independent learning and
          empower students to embrace responsibility. Students at our top
          private schools learn to celebrate diversity in a spirit of
          understanding and tolerance that helps them become citizens of the
          world.
        </Typography>
      </Stack>
    </Content>
  );
}

export default Values;
