import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Collapse,
  List,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import useHandleNavbarMenu from "./usehandleNavbarMenu";
import NavbarMenu from "./NavbarMenu";

function MenuItemComponent({ children, depth, handleClick, itemProps }) {
  const { selected, ...rest } = itemProps;
  if (depth === 0) {
    return (
      <Button
        sx={{ color: "inherit" }}
        onClick={handleClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </Button>
    );
  }
  return (
    <MenuItem
      onClick={handleClick}
      selected={selected}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      sx={{ textWrap: "wrap" }}
    >
      {children}
    </MenuItem>
  );
}

MenuItemComponent.propTypes = {
  children: PropTypes.node,
  handleClick: PropTypes.func,
  depth: PropTypes.number,
  itemProps: PropTypes.shape({ selected: PropTypes.bool }),
};
MenuItemComponent.defaultProps = {
  children: null,
  handleClick: () => {},
  depth: 0,
  itemProps: {},
};

function SubMenuContainer({
  children,
  open,
  handleClose,
  anchorEl,
  depth,
  isSmallScreen,
}) {
  if (depth === 0) {
    return (
      <NavbarMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        location={isSmallScreen ? "left-start" : "bottom-start"}
      >
        {children}
      </NavbarMenu>
    );
  }
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {children}
      </List>
    </Collapse>
  );
}

SubMenuContainer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({}),
  depth: PropTypes.number,
  isSmallScreen: PropTypes.bool,
};

SubMenuContainer.defaultProps = {
  children: null,
  open: false,
  depth: 0,
  isSmallScreen: false,
  anchorEl: null,
};

function NavMenuItem(props) {
  const { title, pages, href, depth, handleParentClose } = props;
  const isMenu = Boolean(pages.length > 0);
  const { anchorEl, handleButtonClick, handleMenuClose } = useHandleNavbarMenu({
    isMenu,
    href,
    handleParentClose,
  });
  const isMenuOpen = Boolean(anchorEl);
  const isSmallScreen = useMediaQuery((theme) =>
    // @ts-ignore
    theme.breakpoints.down("md")
  );

  const getTitle = () => {
    if (depth === 0) {
      return title;
    }
    return (
      <Typography variant="body1" sx={{ pl: (depth - 1) * 2 }}>
        {title}
      </Typography>
    );
  };

  const moreIcon = useMemo(() => {
    const getMoreIcon = () => {
      if (depth === 0) {
        const iconMap = {
          "large-screen": [ExpandMore, ExpandLess],
          "small-screen": [ChevronLeft, ChevronRight],
        };
        return iconMap[isSmallScreen ? "small-screen" : "large-screen"][
          Number(isMenuOpen)
        ];
      }
      return [ExpandMore, ExpandLess][Number(isMenuOpen)];
    };
    const MoreIcon = getMoreIcon();
    return <MoreIcon fontSize="small" />;
  }, [depth, isSmallScreen, isMenuOpen]);

  const shouldShowIconInFront = isSmallScreen && depth === 0;

  return (
    <Stack>
      <MenuItemComponent
        handleClick={handleButtonClick}
        depth={depth}
        itemProps={{ selected: isMenuOpen }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {isMenu && shouldShowIconInFront && moreIcon}
          {getTitle()}
          {isMenu && !shouldShowIconInFront && moreIcon}
        </Stack>
      </MenuItemComponent>
      {isMenu && (
        <SubMenuContainer
          anchorEl={anchorEl}
          open={isMenuOpen}
          handleClose={handleMenuClose}
          depth={depth}
          isSmallScreen={isSmallScreen}
        >
          {pages.map((page) => (
            <NavMenuItem
              title={page.title}
              href={page.href}
              pages={page.pages}
              key={page.title}
              depth={depth + 1}
              handleParentClose={() => {
                handleMenuClose();
                handleParentClose();
              }}
            />
          ))}
        </SubMenuContainer>
      )}
    </Stack>
  );
}

NavMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape(NavMenuItem.propTypes)),
  href: PropTypes.string,
  depth: PropTypes.number,
  handleParentClose: PropTypes.func,
};

NavMenuItem.defaultProps = {
  pages: [],
  href: "#",
  depth: 0,
  handleParentClose: () => {},
};

export default NavMenuItem;
