import React from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { ReactComponent as TargetIcon } from "assets/target.svg";
import { ReactComponent as TechLearnIcon } from "assets/techlearn.svg";
import LMPhoto from "assets/LM.webp";
import Content from "components/Content";

const methodologies = [
  {
    title: "Project Based Learning",
    icon: WorkspacePremiumIcon,
    description:
      "Long term and student centered, project-based learning is a rigorous hands-on approach to learning core subject matter and basic skills with meaningful activities that examine complex, real-world issues. Project-based learning helps students develop and retain useful, working knowledge of subjects that are often taught in isolation and abstraction.",
  },
  {
    title: "Competency Based Learning",
    icon: (props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <SvgIcon component={TargetIcon} {...props} inheritViewBox />
    ),
    description:
      "Competency-based learning is an approach to education that focuses on the student’s demonstration of desired learning outcomes as central to the learning process. Competency-based learning is an effective model, potentially reducing inefficiency (including time spent learning) and increasing pedagogical precision and student achievement.",
  },
  {
    title: "Technology Based Learning",
    icon: (props) => (
      <SvgIcon
        component={TechLearnIcon}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        inheritViewBox
      />
    ),
    description:
      "The MSY Divine School harnesses new technologies in its curriculum, taking up the responsibility of creating responsible digital citizens. We believe that our students should be equipped with quality information . Through the intelligent use of technology, combined with new approaches to education, a more personalized style of learning can be realized.",
  },
  {
    title: "Critical Thinking",
    icon: PsychologyIcon,
    description:
      "Critical thinking is one of the most valuable skills that every student must learn mastering. It is not just limited to problem-solving skills in class but also in real-life situations. A variety of skills are taught while learning about critical thinking, this involves any kind of circumstances that requires planning, analysis, and reflection.",
  },
];

function Methodologies() {
  return (
    <Box>
      <Content
        imageProps={{
          image: LMPhoto,
          alt: "Learning Methodologies",
          sizes:
            "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
        }}
        direction="row-reverse"
      >
        <Stack justifyContent="center" spacing={2}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Learning Methodologies
          </Typography>
          <Typography variant="body1" paragraph>
            Offering a world-class education centered on the CBSE curriculum,
            designed to foster inquiry through thematic, experiential and
            project-based learning. Teaching and learning is designed for
            exploration, creativity, independent thinking and experimentation.
            It is woven into an integrated approach to provide meaningful
            learning experiences and establish cross-curricular links. This will
            lead to acquisition of skills in the Language Arts, Sciences,
            Mathematics, Social Studies, Art forms, Music, Physical & Health
            Education.
          </Typography>
        </Stack>
      </Content>
      <Container sx={{ my: 8 }}>
        <Grid container spacing={7} justifyContent="center">
          {methodologies.map((methodology) => (
            <Grid item xs={12} md={6} key={methodology.title}>
              <Stack spacing={2} alignItems="center">
                <methodology.icon color="secondary" sx={{ fontSize: 90 }} />
                <Typography variant="h5" align="center">
                  {methodology.title}
                </Typography>
                <Typography variant="body1" align="center">
                  {methodology.description}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Methodologies;
