import React from "react";
import BgAttachFixBox from "components/BgAttachFixBox";
import CurriculumBG from "assets/curriculum-bg.jpg";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  alpha,
} from "@mui/material";

const aims = [
  "Encourage students to think critically and solve problems though project and theme based learning",
  "Incorporate STREAM, i.e. Integrated and blended learning of the most significant streams of Science, Technology, Reading, English, Art and Mathematics.",
  "Inculcate the integration of art in the curriculum to enhance the appreciation for aesthetics by covering major spheres like music, dance, visual arts and theatre.",
  "Foster holistic development through sports and HPE, i.e. Health and Physical Education.",
  "Offer activities for curricular and co-curricular enrichment via clubs and work education.",
  "Develop ‘Reflective Learning Practices’ for students to assess and understand their strengths and work on areas to support self-learning and personal development through Student-Led Conferences.",
  "Engage students in addressing real-world problems and issues important to humanity to lead change.",
  "Provide differentiated teaching-learning activities.",
];
function CurriculumAims() {
  return (
    <BgAttachFixBox
      // @ts-ignore
      backgroundImage={CurriculumBG}
      sx={{
        py: 8,
      }}
    >
      <Container>
        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            p: 6,
            m: 2,
            bgcolor: "common.white",
            color: (theme) => alpha(theme.palette.common.black, 0.87),
            borderRadius: 10,
            position: "relative",
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", position: "relative" }}
            align="center"
          >
            Our Curriculum aims to
          </Typography>
          <List disablePadding sx={{ listStyleType: "disc" }}>
            {aims.map((aim) => (
              <ListItem key={aim} disablePadding sx={{ display: "list-item" }}>
                <ListItemText primary={aim} />
              </ListItem>
            ))}
          </List>
        </Stack>
      </Container>
    </BgAttachFixBox>
  );
}

export default CurriculumAims;
