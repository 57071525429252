import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function MainStack({ children }) {
  return (
    <Stack
      spacing={{ xs: 5, sm: 10, lg: 15 }}
      sx={{ py: { xs: 5, sm: 10, lg: 15 } }}
    >
      {children}
    </Stack>
  );
}

MainStack.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainStack;
