import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  SvgIcon as MuiSvgIcon,
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { Facebook, YouTube } from "@mui/icons-material";
import LocationIcon from "@mui/icons-material/LocationOnRounded";
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded";
import EmailIcon from "@mui/icons-material/EmailRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTimeRounded";
import Logo from "assets/logo.webp";
import { ReactComponent as Instagram } from "assets/instagram.svg";
import { blueGrey } from "@mui/material/colors";
import ResponsiveImage from "./ResponsiveImage";

function socialIcon({ href, iconProps, color }) {
  const { Icon, isSvgFile } = iconProps;
  let IconComponent;
  if (isSvgFile) {
    IconComponent = function SvgIcon(props) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <MuiSvgIcon component={Icon} inheritViewBox {...props} />;
    };
  } else {
    IconComponent = Icon;
  }
  return (
    <IconButton href={href} key={href} target="_blank">
      <IconComponent htmlColor={color} sx={{ bgcolor: "common.white" }} />
    </IconButton>
  );
}

const socialIcons = [
  {
    href: "https://www.facebook.com/profile.php?id=100089347956577",
    iconProps: { Icon: Facebook },
    color: "#316FF6",
  },
  {
    href: "https://www.instagram.com/msydivineschool/",
    iconProps: { Icon: Instagram, isSvgFile: true },
    color: "#E1306C",
  },
  {
    href: "https://www.youtube.com/@MSYDIVINESCHOOLGAYA",
    iconProps: { Icon: YouTube },
    color: "#CD201F",
  },
];

const quickLinks = [
  { title: "Home", href: "/" },
  { title: "About Us", href: "/values-mission-vision" },
  { title: "Notice Board", href: "/notice-board" },
  { title: "Gallery", href: "gallery" },
  { title: "Admissions", href: "/admissions" },
];

const reachUsItems = [
  { text: "Jail Road, Gaya 823001", icon: LocationIcon },
  { text: "+919835336498", icon: PhoneIcon },
  { text: "enquiry@msydivineschool.in", icon: EmailIcon },
  { text: "Mon - Sat: 7:00 AM - 3:00 PM", icon: AccessTimeIcon },
];

function Footer() {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <Box sx={{ bgcolor: "primary.main", color: "primary.contrastText", py: 7 }}>
      <Container>
        <Grid container spacing={8} justifyContent="space-around">
          <Grid item>
            <Stack>
              <ResponsiveImage image={Logo} alt="school logo" sizes="131px" />

              <Typography variant="body1" align="center">
                Copyright &copy; {year}
              </Typography>
              <Typography variant="body1" align="center">
                All rights reserved
              </Typography>
              <Stack direction="row" justifyContent="center">
                {socialIcons.map((icon) => socialIcon(icon))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2}>
              <Typography variant="h6">Quick Links</Typography>
              <List disablePadding>
                {quickLinks.map((link) => (
                  <ListItem key={link.title}>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      underline="hover"
                      to={link.href}
                    >
                      <Typography key={link.title} variant="body1">
                        {link.title}
                      </Typography>
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={2}>
              <Typography variant="h6">Reach Us</Typography>
              <List disablePadding>
                {reachUsItems.map((item) => (
                  <ListItem key={item.text} disableGutters>
                    <ListItemIcon
                      sx={{
                        color: "inherit",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "transparent",
                          border: 1,
                          borderColor: blueGrey.A100,
                        }}
                      >
                        <item.icon />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">{item.text}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
