import { styled } from "@mui/material/styles";

const BackgroundImage = styled("img")({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  zIndex: -1,
  objectFit: "cover",
  objectPosition: "center",
});

export default BackgroundImage;
