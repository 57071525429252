import React from "react";
import Curriculum from "components/Curriculum";
import MethodologyImage from "assets/primaryschool.webp";
// @ts-ignore
import BGImage from "assets/primary-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";

function PrimarySchool() {
  return (
    <Curriculum
      bgImage={BGImage}
      heading="Primary School"
      title="Primary School (Grade I to V)"
      description="The Primary School consists of Grade I to V. Besides possessing an exclusive Computer Laboratory and Resource Centre, the Junior School also has classrooms individually equipped for art, dance and music. Educational tours and pleasure trips are organised to nurture values and life skills and to develop the personality of the child. At one of the most impressionable stages in their lives, the Juniors are encouraged to seek knowledge through exploration and discovery. The activity rooms and the playgrounds provide adequate avenues for them to engage in a wide range of activities to learn, engage and develop mentally. To cater to the
needs of this age group, an integrated package of teaching, learning and assessment is delivered through the PRIDE PRIMARY TEACHING METHODOLOGY approach."
      methodologyImage={MethodologyImage}
      methodologyDetails={[
        {
          title: "Evaluation",
          description:
            "While Grade I and II continue to be assessed informally through year-long assessment modules spaced out across three terms, Grade III to V have formal assessment schemes over a three term system.",
        },
        {
          title: "Subjects",
          description:
            "Languages | Science | Mathematics | Social Studies | Computer Science",
        },
        {
          title: "Co-Curricular Activities",
          description:
            "Coding Lab | Art and Craft | Dance | Music | Yoga | Physical Education",
        },
      ]}
    />
  );
}

export default PrimarySchool;
