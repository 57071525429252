import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  useTheme,
  Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// @ts-ignore
import Logo from "assets/logo.webp?sizes[]=60";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NavMenuItem from "./NavMenuItem";

const pages = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "About",
    pages: [
      {
        title: "Values, Mission & Vision",
        href: "/values-mission-vision",
      },
      {
        title: "Management Committee",
        href: "/management",
      },
      {
        title: "Learning Methodology",
        href: "/learning-methodology",
      },
    ],
  },
  {
    title: "Infrastructure",
    pages: [
      {
        title: "Classroom",
        href: "/classroom",
      },
      {
        title: "Laboratories",
        href: "/laboratories",
      },
      {
        title: "Sports Facilities",
        href: "/sports-facilities",
      },
      {
        title: "Safety & Security",
        href: "/safety-security",
      },
    ],
  },
  {
    title: "Curriculum",
    pages: [
      {
        title: "Pre-Primary",
        href: "/pre-primary",
      },
      {
        title: "Primary",
        href: "/primary",
      },
      {
        title: "Middle School",
        href: "/middle-school",
      },
      {
        title: "Senior School",
        href: "/senior-school",
      },
    ],
  },
  {
    title: "Info Center",
    pages: [
      {
        title: "Notice Board",
        href: "/notice-board",
      },
      {
        title: "School Brochure",
        href: "/school-brochure",
      },
      {
        title: "Gallery",
        href: "/gallery",
      },
    ],
  },
  {
    title: "Admissions",
    href: "/admissions",
  },
  {
    title: "Reach Us",
    href: "/reach-us",
  },
];

function Navbar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Container>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{ display: "inline-flex" }}
              >
                <Box
                  component="img"
                  src={Logo}
                  alt="school logo"
                  sx={{ height: 60, width: 60 }}
                />
              </Link>
              <Stack
                component="nav"
                direction="row"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                {pages.map((page) => (
                  <NavMenuItem
                    key={page.title}
                    title={page.title}
                    href={page.href}
                    pages={page.pages}
                  />
                ))}
              </Stack>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setDrawerOpen(true)}
                sx={{ display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="right"
        sx={{ "& .MuiDrawer-paper": { overflowY: "visible" } }} // Make flyout menu visible
      >
        <Toolbar>
          <IconButton onClick={() => setDrawerOpen(false)}>
            {theme.direction === "ltr" ? (
              <ArrowForwardIcon />
            ) : (
              <ArrowBackIcon />
            )}
          </IconButton>
        </Toolbar>
        <Divider />
        <Stack role="presentation" component="nav">
          {pages.map((page) => (
            <NavMenuItem
              key={page.title}
              title={page.title}
              href={page.href}
              pages={page.pages}
              handleParentClose={() => setDrawerOpen(false)}
            />
          ))}
        </Stack>
      </Drawer>
    </>
  );
}

export default Navbar;
