import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, alpha } from "@mui/material";

function ResponsiveImage(props) {
  const { image, alt, shouldApplyBoxShadow, sizes, imgAttrs } = props;
  const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(true);

  return (
    <Box
      sx={{
        position: "relative",
        aspectRatio: image.width / image.height,
        ...(shouldShowPlaceholder && {
          backgroundSize: "cover",
          backgroundImage: `url(${image.placeholder})`,
        }),
        "&:after": shouldApplyBoxShadow && {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          boxShadow: (theme) =>
            `inset 0 0 0 8px ${alpha(theme.palette.common.black, 0.5)}`,
          pointerEvents: "none",
        },
      }}
    >
      <Box
        component="img"
        src={image.src}
        srcSet={image.srcSet}
        sizes={sizes}
        alt={alt}
        onLoad={() => setShouldShowPlaceholder(false)}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...imgAttrs}
      />
    </Box>
  );
}

ResponsiveImage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  alt: PropTypes.string,
  shouldApplyBoxShadow: PropTypes.bool,
  sizes: PropTypes.string,
  imgAttrs: PropTypes.shape({}),
};

ResponsiveImage.defaultProps = {
  alt: "",
  shouldApplyBoxShadow: false,
  sizes: "100vw",
  imgAttrs: {},
};

export default ResponsiveImage;
