import React from "react";
import PropTypes from "prop-types";
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";

function NavbarMenu(props) {
  const { open, anchorEl, children, handleClose, location } = props;
  const getGrowTransformOrigin = (placement) => {
    const transformOrigin = {
      "bottom-start": "left top",
      "bottom-end": "right top",
      "top-start": "left bottom",
      "top-end": "right bottom",
      "left-start": "right top",
      "left-end": "right bottom",
      "right-start": "left top",
      "right-end": "left bottom",
      bottom: "center top",
      top: "center bottom",
      left: "right center",
      right: "left center",
    };
    return transformOrigin[placement];
  };
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      placement={location}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TransitionProps}
          style={{
            transformOrigin: getGrowTransformOrigin(placement),
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open}>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

NavbarMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  location: PropTypes.string,
};

NavbarMenu.defaultProps = {
  location: "bottom-start",
  anchorEl: null,
};

export default NavbarMenu;
