import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
  Autoplay,
  Pagination,
  Navigation,
  Mousewheel,
  Keyboard,
} from "swiper/modules";
import { Container, Grid, useMediaQuery } from "@mui/material";
import "./testimonial.css";
import ResponsiveImage from "components/ResponsiveImage";

function TestimonialCarousel(props) {
  const { testimonials } = props;
  // @ts-ignore
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const swiperModules = [Autoplay, Pagination];
  if (!isSmallScreen) {
    swiperModules.push(Navigation, Mousewheel, Keyboard);
  }
  return (
    <Container>
      <Swiper
        cssMode
        modules={swiperModules}
        spaceBetween={30}
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation={!isSmallScreen}
        className="testimonialSwiper"
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.testimonial}>
            <Grid
              container
              sx={{ px: !isSmallScreen && 8, pb: 4 }}
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item container justifyContent="center" xs={12} md={4}>
                <Grid item sx={{ width: 240, height: 220 }}>
                  <ResponsiveImage
                    image={testimonial.image}
                    alt={testimonial.name}
                    sizes="240px"
                    imgAttrs={{ loading: "lazy" }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <figure
                  className="quote"
                  style={{ height: isSmallScreen ? 300 : 240 }}
                >
                  <blockquote>{testimonial.testimonial}</blockquote>
                  <figcaption>
                    &mdash; {testimonial.name}, {testimonial.title}
                  </figcaption>
                </figure>
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}

TestimonialCarousel.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      testimonial: PropTypes.string.isRequired,
      image: ResponsiveImage.propTypes.image,
    })
  ).isRequired,
};

export default TestimonialCarousel;
