import React from "react";
import Curriculum from "components/Curriculum";
import MethodologyImage from "assets/seniorschool.webp";
// @ts-ignore
import BGImage from "assets/senior-bg.jpg?sizes[]=300,sizes[]=692,sizes[]=1310,sizes[]=1720,sizes[]=2048";

function SeniorSchool() {
  return (
    <Curriculum
      bgImage={BGImage}
      heading="Senior School"
      title="Senior School (Grade IX & X)"
      description="Now the stage is set for shaping the future based on subject preferences and skills gained. During this stage, the main objective is to hone the knowledge and skills gained at the Middle school.While the curriculum has been designed as per the CBSE guidelines, more emphasis is laid on the teaching/learning process through continuous assessments, doubt solving, practice and mock tests and science and math practical classes; with experienced and specialised faculty members guiding the students towards academic excellence. Students are encouraged to participate in various science and technology fairs and competitions to help them achieve conceptual confidence in all subjects, thus gradually paving the way for the Class X board examinations.
"
      methodologyImage={MethodologyImage}
      methodologyDetails={[
        {
          title: "Evaluation",
          description:
            "Evaluation is based on a two-term format as per the present CBSE norms.",
        },
        {
          title: "Subjects",
          description:
            "Languages | Math | Physics | Chemistry | Biology | History | Geography | Civics | Economics | IT(Information Technology)",
        },
        {
          title: "Co-Curricular Activities",
          description:
            "Art and Craft | Dance | Music| Yoga | Physical Education",
        },
      ]}
    />
  );
}

export default SeniorSchool;
