import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const BgAttachFixBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "backgroundImage",
  // @ts-ignore
})(({ theme, backgroundImage }) => ({
  position: "relative",
  color: theme.palette.common.white,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: alpha(theme.palette.common.black, 0.8),
    opacity: 0.8,
  },
}));

export default BgAttachFixBox;
