import React from "react";
// @ts-ignore
import BGImage from "assets/notice-board-bg.jpg?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import BannerImage from "assets/banner-shape.png";
import LocationIcon from "@mui/icons-material/LocationOnRounded";
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded";
import EmailIcon from "@mui/icons-material/EmailRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTimeRounded";
import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import QueryForm from "./QueryForm";
import Hero from "./Hero";
import MainStack from "./MainStack";
import ResponsiveImage from "./ResponsiveImage";

const reachUsItems = [
  {
    title: "School Address",
    text: "Jail Road, Opposite CRPF camp, Gaya 823001",
    icon: LocationIcon,
  },
  { title: "Phone", text: "+919835336498", icon: PhoneIcon },
  { title: "Send Email", text: "enquiry@msydivineschool.in", icon: EmailIcon },
  {
    title: "Opening time",
    text: "Mon - Sat: 7:00 AM - 3:00 PM",
    icon: AccessTimeIcon,
  },
];

function Contact() {
  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <Hero
          bgProps={{ image: BGImage, title: "Contact background" }}
          heightScale={0.8}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h1" sx={{ fontWeight: "normal" }}>
              Reach Us
            </Typography>
          </Container>
        </Hero>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        >
          <ResponsiveImage image={BannerImage} alt="Banner" sizes="100vw" />
        </Box>
      </Box>
      <MainStack>
        <Box>
          <Container>
            <Grid container spacing={4} alignItems="center">
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                xs={12}
                md={5}
              >
                <Grid item>
                  <List sx={{ bgcolor: "grey.A100", p: { xs: 2, md: 4 } }}>
                    {reachUsItems.map((item) => (
                      <ListItem key={item.title} sx={{ mb: 2 }}>
                        <ListItemIcon
                          sx={{
                            color: "inherit",
                            mr: 2,
                          }}
                        >
                          <Avatar
                            sx={{
                              bgcolor: "common.black",
                              width: 56,
                              height: 56,
                              "&:hover": { bgcolor: "primary.main" },
                            }}
                          >
                            <item.icon />
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          secondary={item.text}
                          primaryTypographyProps={{ variant: "h6" }}
                          secondaryTypographyProps={{ variant: "body1" }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Grid item xs={12} md={7}>
                <QueryForm />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider />
        <Box>
          <Container>
            <Typography
              variant="h4"
              sx={{ fontWeight: "fontWeightBold", mb: 4 }}
              gutterBottom
              textAlign="center"
            >
              Location
            </Typography>
            <iframe
              title="MSY Divine School"
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              allowfullscreen
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJwb2uhb4r8zkREUoukm7Q48g&key=AIzaSyCqQMH41znnRg0UfVYBWA4NrUGLOyu-5os"
            />
          </Container>
        </Box>
      </MainStack>
    </Box>
  );
}

export default Contact;
