import React from "react";
// @ts-ignore
import BGImage from "assets/sports-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "components/HeadingDisplay";
import { Box, Stack, Typography } from "@mui/material";
import Content from "components/Content";
import JuniorPlayAreaPic from "assets/juniorplayarea.webp";
import BadmintonPic from "assets/badminton.webp";
import FootballPic from "assets/football.webp";
import TabletennisPic from "assets/tabletennis.webp";
import { ContactInvite } from "components/Invite";
import MainStack from "components/MainStack";

const facilities = [
  {
    title: "Junior Play Area",
    description:
      "It is our belief that there is nothing better than the laughter of children at play, for which we have dedicated to our tiny-tots of classes Nursery to UKG, the colourful and vibrant play area. The play area is equipped with swings, slides, and other play equipment. The play area is designed to provide a safe and fun environment for our junior students. The play area is also equipped with a soft floor to ensure the safety of our students.",
    image: JuniorPlayAreaPic,
  },
  {
    title: "Badminton Court",
    description:
      "The school has a badminton court which is used for physical education classes and for students to practice and play during their free time. The court is equipped with the necessary equipment and is maintained regularly to ensure the safety of our students. We provide trained professionals who use modern coaching techniques to bring out the best in the students",
    image: BadmintonPic,
  },
  {
    title: "Table Tennis",
    description:
      "The indoor sports arena provides ample and marvellous opportunities for the students to explore their potential and hone their table tennis skills with state-of-the-art facilities and the latest sporting gear. The tables and other equipment are of a superior standard, robust, easy to use and in compliance with international competition standards with zero compromise on quality and safety.",
    image: TabletennisPic,
  },
  {
    title: "Football Playground",
    description:
      "Another feather in the already well adorned sporting cap of the school is the Football Playground, complete with all the latest amenities. This playground is also used for other outdoor sports which require a bigger ground to play. The playground is maintained on a regular basis to keep the grass greener and rest area clean and comfortable.",
    image: FootballPic,
  },
];

function SF() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Sports Facilities" />
      <MainStack>
        {facilities.map((facility, index) => (
          <>
            <Content
              imageProps={{
                image: facility.image,
                alt: facility.title,
                sizes:
                  "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
              }}
              key={facility.title}
              direction={index % 2 ? "row-reverse" : "row"}
            >
              <Stack justifyContent="center" spacing={2}>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {facility.title}
                </Typography>
                <Typography variant="body1">{facility.description}</Typography>
              </Stack>
            </Content>
            {index === 1 && <ContactInvite />}
          </>
        ))}
      </MainStack>
    </Box>
  );
}

export default SF;
