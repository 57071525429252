import React, { useEffect } from "react";
// @ts-ignore
import BGImage from "assets/brochure-bg.webp?sizes[]=692,sizes[]=1290,sizes[]=1710,sizes[]=2048";
import { Box, Container } from "@mui/material";
import HeadingDisplay from "./HeadingDisplay";

let setUpPromise = null;
function Brochure() {
  useEffect(() => {
    if (!setUpPromise) {
      const script = document.createElement("script");
      script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
      document.body.appendChild(script);
      setUpPromise = new Promise((resolve) => {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      });
    }
    setUpPromise.then(() => {
      // @ts-ignore
      const adobeDCView = new window.AdobeDC.View({
        clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
        divId: "adobe-dc-view",
      });
      adobeDCView.previewFile(
        {
          content: { location: { url: process.env.REACT_APP_BROCHURE_PDF } },
          metaData: { fileName: "School Brochure" },
        },
        { embedMode: "IN_LINE" }
      );
    });
  }, []);
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="School Brochure" />
      <Container>
        <Box id="adobe-dc-view" my={8} />
      </Container>
    </Box>
  );
}

export default Brochure;
