import React from "react";
// @ts-ignore
import BGImage from "assets/lab-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "components/HeadingDisplay";
import { Box, Stack, Typography } from "@mui/material";
import Content from "components/Content";
import ScienceLabPic from "assets/science-lab.webp";
import ComputerLabPic from "assets/computer-lab.webp";
import MainStack from "components/MainStack";

const labs = [
  {
    title: "Science Lab",
    description:
      "Our Physics, Chemistry and Biology Labs are fully equipped with all modern facilities and equipment to allow children to engage in learning through research. The Labs have been planned keeping the safety of the students in mind. Well-trained professionals assist and engage children, encouraging them to work in groups as well as individually to enable them to correlate practical and theoretical experiences. We believe children learn by doing things and not just hearing them. Our modern facilities provide students a chance to not only carry out experiments based on existing theories but also encourage them to carry out research and experiment based on their learning and thinking. Our teachers are well-trained professionals who assist and engage children, encouraging them to work in groups as well as individually to enable them to correlate practical and theoretical experiences.",
    image: ScienceLabPic,
  },
  {
    title: "Computer Lab",
    description:
      "We are aware of current world and its demand and we want our students to be future-ready. We have one of its kind, the Coding Lab for our students. The computer lab is equipped with the latest technology and software. The lab is designed to provide students with the best possible learning experience. The Lab empowers kids with the computational thinking knowledge and skills necessary to succeed in the future economy. Learning to code is fun and easy here.",
    image: ComputerLabPic,
  },
];

function Lab() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Laboratories" />
      <MainStack>
        {labs.map((lab, index) => (
          <Content
            imageProps={{
              image: lab.image,
              alt: lab.title,
              sizes:
                "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
            }}
            key={lab.title}
            direction={index % 2 ? "row-reverse" : "row"}
          >
            <Stack justifyContent="center" spacing={2}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {lab.title}
              </Typography>
              <Typography variant="body1">{lab.description}</Typography>
            </Stack>
          </Content>
        ))}
      </MainStack>
    </Box>
  );
}

export default Lab;
