import React from "react";
import Filler from "components/Filler";
import AdmissionBg from "assets/admission-bg.jpg";

function ContactInvite() {
  return (
    <Filler
      title="Become a part of our family!"
      subtitle="Fill up query form and we'll send you details about new
              courses and events."
      href="/reach-us"
      actionText="contact us"
    />
  );
}

function AdmissionInvite() {
  return (
    <Filler
      title="Admissions Open Now"
      subtitle="We are accepting applications for new admissions."
      href="/admission"
      actionText="apply now"
      backgroundImage={AdmissionBg}
    />
  );
}

function ContactInvite2() {
  return (
    <Filler
      title="Begin your journey with us!"
      subtitle="Let's build a bright future for your child."
      href="/reach-us"
      actionText="contact us"
      backgroundImage={AdmissionBg}
    />
  );
}

export { ContactInvite, AdmissionInvite, ContactInvite2 };
