import React from "react";
import { Box, Stack, Typography } from "@mui/material";
// @ts-ignore
import BGImage from "assets/mc-bg.jpg?sizes[]=300,sizes[]=940,sizes[]=1290,sizes[]=1570,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "components/HeadingDisplay";
import MaheshPhoto from "assets/Mahesh.webp";
import SitendraPhoto from "assets/Sitendra.webp";
import Content from "components/Content";
import MainStack from "components/MainStack";

const members = [
  {
    name: "Mahesh Singh Yadav",
    title: "Social Worker",
    image: MaheshPhoto,
    description:
      "Mahesh Singh Yadav is one of the recognized names in city of Gaya and state of Bihar. He opened one of the oldest college affiliated by Magadh University of Bihar. He is one of the oldest  educationist and a social leader who always worked for the betterment of our society. He is always looking forward to guide everyone who is intrested in education and learning. For many decades he has been guru and a blessing for our society and city. As part of Managing Committee he is helping MSY Divine School, he is guiding us to nurture every students very carefully.",
  },
  {
    name: "Sitendra Kumar",
    title: "Advocate",
    image: SitendraPhoto,
    description:
      "Mr. Sitendra has completd his studies from Bharati Vidhyapeeth, New Delhi. He worked his way up in Justice field and currently plays the role of Advocate in a Gaya Civil Court. He is passionate about his work and has worked extensively in his field helping people to fight for their rights. Along with work Sitendra is very keen on overall development of society at large. Even after spending many years fighting for people, he always wanted to have other options to help in the development of society. As part of Managing Committee he is helping MSY Divine School, realize its full potential to bring world class education in Gaya, Bihar.",
  },
];

function MC() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Management Committee" />
      <MainStack>
        {members.map((member, index) => (
          <Content
            imageProps={{
              image: member.image,
              alt: member.name,
              sizes:
                "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
            }}
            key={member.name}
            direction={index % 2 ? "row-reverse" : "row"}
          >
            <Stack justifyContent="center" spacing={2}>
              <Box>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {member.name}
                </Typography>
                <Typography variant="h6">{member.title}</Typography>
              </Box>
              <Typography variant="body1">{member.description}</Typography>
            </Stack>
          </Content>
        ))}
      </MainStack>
    </Box>
  );
}

export default MC;
