import React from "react";
import Curriculum from "components/Curriculum";
import MethodologyImage from "assets/middleschool.webp";
// @ts-ignore
import BGImage from "assets/senior-bg.jpg?sizes[]=300,sizes[]=692,sizes[]=1310,sizes[]=1720,sizes[]=2048";

function MiddleSchool() {
  return (
    <Curriculum
      bgImage={BGImage}
      heading="Middle School"
      title="Middle School (Grade VI to VIII)"
      description="At this age, children are in a stage of intellectual and social development and they begin to connect themselves with the community in a larger sense. To cater to the needs of this age group, an integrated package of teaching, learning and assessment is delivered through the PRIDE MIDDLE TEACHING METHODOLOGY approach. Students are directed towards independent learning and are guided to take on more responsibility. The main objective of this stage is to help the children make connections between the meaningful experiences in life and the courses of study. Teachers employ audio-visual aids to turn the delivery process into an interactive session. Subjects are subdivided further, leading to increased complexity and hence the need for creative methods to drive the concept home. As per the norms of CBSE, the School follows a two-term assessment format and aims at improving the child’s performance at every step by identifying his/her learning difficulties right from the beginning."
      methodologyImage={MethodologyImage}
      methodologyDetails={[
        {
          title: "Evaluation",
          description:
            "Evaluation is based on a two-term format as per the present CBSE norms.",
        },
        {
          title: "Subjects",
          description:
            "Languages | Physics | Chemistry | Biology | Mathematics | Computer Science | History | Civics |Geography",
        },
        {
          title: "Co-Curricular Activities",
          description:
            "Coding Lab | Art and Craft | Dance | Music | Yoga | Physical Education",
        },
      ]}
    />
  );
}

export default MiddleSchool;
