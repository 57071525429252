import React from "react";
import { Typography } from "@mui/material";
import TestimonialBG from "assets/testimonial-bg.webp";
import TriveniKumar from "assets/triveni-kumar.webp";
import ChandrakantKumar from "assets/chandrakant-kumar.webp";
import SunitaKumari from "assets/sunita-kumari.webp";
import PratapSingh from "assets/pratap-singh.webp";
import BgAttachFixBox from "components/BgAttachFixBox";
import TestimonialCarousel from "./Carousel";

const testimonials = [
  {
    name: "Triveni Kumar",
    image: TriveniKumar,
    title: "Railway Employee",
    testimonial:
      "Enrolling our child at MSY Divine School was the best decision we ever made! The teachers are incredibly dedicated, and the curriculum is both challenging and engaging. Our child has thrived academically and socially, thanks to the nurturing environment provided by the School. We couldn't be happier with our experience here.",
  },
  {
    name: "Chandrakant Kumar",
    image: ChandrakantKumar,
    title: "Civil Engineer",
    testimonial:
      "We're so grateful to have found MSY Divine School for our daughter. The faculty and staff go above and beyond to ensure each student's success. The extracurricular activities offered are diverse and enriching, allowing our child to explore her interests beyond the classroom. The sense of community at the school is unparalleled.",
  },
  {
    name: "Sunita Kumari",
    image: SunitaKumari,
    title: "Housewife",
    testimonial:
      "As parents, we were initially apprehensive about finding the right school for our son, but MSY Divine School exceeded all our expectations. The personalized attention he receives from his teachers has helped him flourish both academically and personally. The school's commitment to fostering a well-rounded education is evident.",
  },
  {
    name: "Pratap Singh",
    image: PratapSingh,
    title: "Businessman",
    testimonial:
      "Choosing MSY Divine School for our children was one of the best decisions we've made as parents. The warm and inclusive atmosphere makes it feel like a second home. The dedication of the staff to each child's individual growth is remarkable. Our children are also developing important life skills that will serve them well in the future.",
  },
];

function Testimonial() {
  return (
    <BgAttachFixBox
      // @ts-ignore
      backgroundImage={TestimonialBG}
      sx={{
        py: 8,
      }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ position: "relative", mb: "1em", fontWeight: "bold" }}
      >
        Happy Parents
      </Typography>
      <TestimonialCarousel testimonials={testimonials} />
    </BgAttachFixBox>
  );
}

export default Testimonial;
