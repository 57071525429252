import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import HeadingDisplay from "./HeadingDisplay";
import MainStack from "./MainStack";
import ResponsiveImage from "./ResponsiveImage";

function Curriculum(props) {
  const {
    bgImage,
    heading,
    title,
    description,
    methodologyImage,
    methodologyDetails,
  } = props;
  return (
    <Box>
      <HeadingDisplay bgImage={bgImage} displayText={heading} />
      <Container>
        <MainStack>
          <Box>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </Box>
          <ResponsiveImage
            image={methodologyImage}
            alt={title}
            sizes="(min-width: 1280px) 1152px, 92.08vw"
          />
          <Grid container justifyContent="center">
            {methodologyDetails.map((methodologyDetail) => (
              <Grid item xs={12} md={6} lg={4} key={methodologyDetail.title}>
                <Card
                  sx={{
                    height: 300,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    m: 4,
                  }}
                  raised
                >
                  <CardContent>
                    <Stack
                      spacing={2}
                      divider={<Divider flexItem />}
                      alignItems="center"
                    >
                      <Typography
                        variant="h5"
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        {methodologyDetail.title}
                      </Typography>
                      <Typography variant="body1" align="center">
                        {methodologyDetail.description}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </MainStack>
      </Container>
    </Box>
  );
}

Curriculum.propTypes = {
  bgImage: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  methodologyImage: PropTypes.string.isRequired,
  methodologyDetails: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

Curriculum.defaultProps = {
  methodologyDetails: [],
};

export default Curriculum;
