import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
// @ts-ignore
import BGImage from "assets/notice-board-bg.jpg?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "./HeadingDisplay";
import { ContactInvite } from "./Invite";
import MainStack from "./MainStack";

const schoolRules = [
  "Every student should carry his/her School Alamance to school, every day.",
  "Student should report to school 10 minutes before the start of the school.",
  "Moving from one classroom to another should be done in silence and in an orderly manner.",
  "All boys’ hair should be trimmed and well groomed. Regular checking will be done.",
  "Forming groups or running in the school corridors, is strictly forbidden.",
  "Students are expected to take care of all school property. Desks/Chairs should not be damaged or scratched and graffiti on the walls will result in serving disciplinary action against the concerned students.",
  "Students are not allowed to carry any sharp instrument or article that can cause damage.",
  "They should not bring any books other than text and library books, to school. However, if the need arises to do so, the Principal/Teacher's permission is to be sought.",
  "Students who come to and leave school in the care of an attendant/servant should not leave the school premises before the attendant/servant arrives. In case of delay, they should report to the school office.",

  "Those who go home alone should not loiter around the school premises. No student will be allowed to enter the school once the school gates are closed.",
  "Students should always be polite and well-mannered and maintain the image and reputation of the school.",
  "Bullying and the use of foul language is strictly prohibited and is a punishable offence.",
  "Exploding of crackers or splashing of color during Diwali/Holi or any other occasion on the school premises or in the school buses is strictly forbidden. Strict action will be taken against student who do not comply with this instruction.",
  "Students should feel privileged to attend functions celebrated in school. Other than serious medical grounds no other cause will be accepted, if a student is absent from school functions.",
  "Students are requested not to give any information to strangers/visitors. Please ask them to make inquiries from school office.",
  "The school has the right to terminate the schooling of any student with unsatisfactory progress in studies or whose conduct is a bad example for others, or if he/she is too old for the class.",
  "Students are required to attend all functions/special classes or coaching/ Saturday Classes in proper School Uniform. Wearing of colored clothes are strictly prohibited.",
  "Parents must check and sign the School Diary every day.",
  "Parents must sign exercise books and return them whenever sent.",
  "Authorization letter along with attested signature must be sent with the person collecting the report card on behalf of the parents in case they are unable to come. However, parents are requested to collect their wards progress report personally.",
  "Children must come in their school uniform if they are accompanying the parents to school for any occasion, especially during PTM’s.",
];

const examinationRules = [
  "Attendance is compulsory for all tests and examinations held during the year. If a student fails to appear for a particular examination, he/she will be given zero. There will be no test conducted on a later date if a student doesn't sit or misses the same.",
  "A minimum of 85% attendance is required for a student to qualify to sit for any test/ examination conducted in school.",
  "Students who are late for any examinations will not be given any extra time to answer the question paper.",
  "No student will be promoted conditionally. Those who score less than the minimum pass marks, as per the promotion criteria, will be detained.",
  "No student should use unfair means in any form. Even helping any other student to cheat / use unfair means, will result in strict action taken against the child.",
  "No student should use unfair means during any exam. If a student is so caught, he will be awarded zero in that subject / paper. A TC will be given to a student who is caught using unfair means the second time.",
  "All students are expected to maintain strict discipline when in the examination room/hall.",
  "No student is expected to take out any blank answer script from the examination hall. Any student found will blank answer script with them, will be dealt with severely.",
  "Any student who is not intending to sit for an exam, must give a reason in writing for their absence.",
  "Students who do not adhere to the above rules will be suspended and finally rusticated, if found guilty of any breach of conduct.",
  "Attendance for a week before the exam is compulsory unless medically unfit.",
];

const notices = [];

function NoticeBoard() {
  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Notice Board" />
      <MainStack>
        <Box>
          <Container>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              Notice Board
            </Typography>
            {notices.length === 0 ? (
              <Typography variant="body1">No notices available</Typography>
            ) : (
              <List disablePadding>
                {notices.map((notice) => (
                  <ListItem key={notice.date} disablePadding>
                    <ListItemButton>
                      <ListItemText
                        primary={notice.title}
                        secondary={notice.date}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Container>
        </Box>
        <ContactInvite />
        <Box>
          <Container>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              School Rules
            </Typography>
            <List disablePadding sx={{ listStyleType: "disc" }}>
              {schoolRules.map((rule) => (
                <ListItem
                  disablePadding
                  key={rule}
                  sx={{ display: "list-item" }}
                >
                  <ListItemText primary={rule} />
                </ListItem>
              ))}
            </List>
          </Container>
        </Box>
        <Box>
          <Container>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              Examination Rules
            </Typography>
            <List disablePadding sx={{ listStyleType: "disc" }}>
              {examinationRules.map((rule) => (
                <ListItem
                  disablePadding
                  key={rule}
                  sx={{ display: "list-item" }}
                >
                  <ListItemText primary={rule} />
                </ListItem>
              ))}
            </List>
          </Container>
        </Box>
      </MainStack>
    </Box>
  );
}

export default NoticeBoard;
