import React from "react";
import PropTypes from "prop-types";
import { Stack, alpha } from "@mui/material";
import BackgroundImage from "components/BackgroundImage";

function Hero(props) {
  const {
    bgProps: { image, title },
    children,
    heightScale,
  } = props;
  return (
    <Stack
      direction="row"
      sx={{
        position: "relative",
        minHeight: { sm: `${50 * heightScale}vh`, xs: `${60 * heightScale}vh` },
        bgcolor: (theme) => alpha(theme.palette.common.black, 0.6),
        color: "common.white",
      }}
    >
      <BackgroundImage
        src={image.src}
        srcSet={image.srcSet}
        sizes="100vw"
        title={title}
      />
      {children}
    </Stack>
  );
}

Hero.propTypes = {
  bgProps: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
  heightScale: PropTypes.number,
};

Hero.defaultProps = {
  children: null,
  heightScale: 1.0,
};

export default Hero;
