import React from "react";
import { ContactInvite } from "components/Invite";
import MainStack from "components/MainStack";
import { Box } from "@mui/material";
import Features from "./Features";
import Display from "./Display";
import Welcome from "./Welcome";
import LearningMethodology from "./LearningMethodology";
import Testimonial from "./Testimonials";

function Home() {
  return (
    <Box>
      <Display />
      <MainStack>
        <Welcome />
        <ContactInvite />
        <LearningMethodology />
        <Testimonial />
        <Features />
      </MainStack>
    </Box>
  );
}

export default Home;
