import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import BgAttachFixBox from "./BgAttachFixBox";

const primaryBgBox = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

function Filler(props) {
  const { href, actionText, title, subtitle, backgroundImage } = props;
  const navigate = useNavigate();
  const WrapperBox = backgroundImage ? BgAttachFixBox : primaryBgBox;
  return (
    <WrapperBox
      sx={{ py: 8 }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(backgroundImage && { backgroundImage: backgroundImage.src })} // Can't use srcSet in background images as of now
    >
      <Container sx={{ position: "relative" }}>
        <Grid
          justifyContent="space-between"
          alignItems="center"
          container
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
            <Typography variant="body1" paragraph>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="inherit"
              onClick={() => navigate(href)}
            >
              {actionText}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </WrapperBox>
  );
}

Filler.propTypes = {
  href: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backgroundImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
  }),
};

Filler.defaultProps = {
  subtitle: "",
  backgroundImage: null,
};

export default Filler;
