import React from "react";
import PropTypes from "prop-types";
import Hero from "components/Hero";
import { Container, Typography } from "@mui/material";

function HeadingDisplay(props) {
  const { bgImage, displayText } = props;

  return (
    <Hero
      bgProps={{ image: bgImage, title: `${displayText} background` }}
      heightScale={0.6}
    >
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h1" sx={{ fontWeight: "normal" }}>
          {displayText}
        </Typography>
      </Container>
    </Hero>
  );
}

HeadingDisplay.propTypes = {
  bgImage: PropTypes.shape({
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string,
    }).isRequired,
  displayText: PropTypes.string,
};

HeadingDisplay.defaultProps = {
  displayText: "",
};

export default HeadingDisplay;
