import React from "react";
import { Stack, Typography } from "@mui/material";
import LMPhoto from "assets/LM.webp";
import Content from "components/Content";

function LearningMethodology() {
  return (
    <Content
      imageProps={{
        image: LMPhoto,
        alt: "Learning Methodology",
        sizes:
          "(min-width: 1280px) 544px, (min-width: 900px) calc(41.67vw + 19px), calc(97.24vw - 24px)",
      }}
      direction="row-reverse"
    >
      <Stack justifyContent="center" spacing={2}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          Learning Methodology
        </Typography>
        <Typography variant="body1" paragraph>
          We believe in learning by doing. Our teaching methodology is based on
          the principle of learning by doing. We believe that students learn
          best when they are engaged in the learning process. We use a variety
          of teaching methods to ensure that all students have the opportunity
          to learn in a way that works best for them.
        </Typography>
        <Typography variant="body1" paragraph>
          Our teachers are trained to use a variety of teaching methods,
          including hands-on activities, group work, and individual instruction.
          We also use technology to enhance the learning experience, with
          interactive whiteboards and computers in every classroom.
        </Typography>
      </Stack>
    </Content>
  );
}

export default LearningMethodology;
