import React from "react";
import PropTypes from "prop-types";
import { Box, Container, Grid } from "@mui/material";
import ResponsiveImage from "components/ResponsiveImage";

function Content(props) {
  const {
    imageProps: { image, alt, shouldApplyBoxShadow, sizes },
    direction,
    children,
  } = props;
  return (
    <Box>
      <Container>
        <Grid container direction={direction} columnSpacing={8} rowSpacing={4}>
          <Grid item container alignItems="center" xs={12} md={6}>
            <Grid item xs>
              <ResponsiveImage
                image={image}
                alt={alt}
                sizes={sizes}
                shouldApplyBoxShadow={shouldApplyBoxShadow}
              />
            </Grid>
          </Grid>
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Content.propTypes = {
  imageProps: PropTypes.shape(ResponsiveImage.propTypes).isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["row", "row-reverse"]),
};

Content.defaultProps = {
  direction: "row",
};

export default Content;
