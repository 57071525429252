import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  alpha,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// @ts-ignore
import BGImage from "assets/gallery-bg.webp?sizes[]=300,sizes[]=1070,sizes[]=1480,sizes[]=1800,sizes[]=2048";
import HeadingDisplay from "./HeadingDisplay";
import MainStack from "./MainStack";
import ResponsiveImage from "./ResponsiveImage";

// @ts-ignore
const images = require.context("assets/gallery", true);
const imageList = images.keys().map((image) => images(image));

function Gallery() {
  const [overlayImage, setOverlayImage] = useState(null);
  const isOverlayOpen = Boolean(overlayImage);

  // @ts-ignore
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleOverlayClose = () => {
    setOverlayImage(null);
  };

  return (
    <Box>
      <HeadingDisplay bgImage={BGImage} displayText="Gallery" />
      <Box
        sx={{
          px: { xs: 2, md: 4, lg: 8, xl: 16 },
        }}
      >
        <MainStack>
          <ImageList variant="masonry" cols={!isSmallScreen ? 3 : 2} gap={8}>
            {imageList.map((image) => (
              <ImageListItem
                key={image.src}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    opacity: 0.8,
                  },
                  backgroundSize: "cover",
                  backgroundImage: `url(${image.placeholder})`,
                }}
                onClick={() => {
                  setOverlayImage(image);
                }}
              >
                <ResponsiveImage
                  image={image}
                  alt="Gallery photo"
                  sizes="(min-width: 1540px) calc(33.29vw - 90px), (min-width: 1200px) calc(33.44vw - 49px), (min-width: 600px) 31.55vw, calc(50vw - 20px)"
                  imgAttrs={{ loading: "lazy" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </MainStack>
      </Box>
      <Modal
        open={isOverlayOpen}
        onClose={handleOverlayClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOverlayOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: isSmallScreen ? "0%" : "50%",
              transform: `translate(${!isSmallScreen ? "-50%" : "0%"}, -50%)`,
            }}
          >
            <Box
              component="img"
              src={overlayImage}
              alt="Selected gallery photo"
              sx={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
            <IconButton
              onClick={handleOverlayClose}
              size="small"
              disableFocusRipple
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.75),
                color: "primary.contrastText",
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "primary.contrastText",
                },
                zIndex: 1,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Gallery;
