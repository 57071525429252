import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
// @ts-ignore
import BGImage from "assets/home-bg.webp?sizes[]=300,sizes[]=880,sizes[]=1200,sizes[]=1540,sizes[]=1810,sizes[]=2048";
import Hero from "components/Hero";

function Display() {
  const navigate = useNavigate();
  return (
    <Hero bgProps={{ image: BGImage, title: "Home background" }}>
      <Container sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Stack justifyContent="center" alignItems="center">
          <Box>
            <Typography variant="h2" sx={{ fontWeight: "bold", mb: "0.25em" }}>
              MSY Divine School
            </Typography>
            <Typography variant="h4" gutterBottom>
              Honesty, Discipline & Wisdom
            </Typography>
            <Typography
              variant="body1"
              paragraph
              gutterBottom
              sx={{ fontSize: "large", maxWidth: "50ch" }}
            >
              A co-educational institution that is committed to providing
              quality education to the students.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/reach-us")}
              sx={{
                mt: 2,
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Stack>
      </Container>
    </Hero>
  );
}

export default Display;
